import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { SEO } from 'app/components/SEO'
import { SpaServices } from 'app/components/SpaServices'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PrivateSpaForm } from 'app/containers/PrivateSpaForm'
import {
  PrivateSpaFormSenderBackend,
  PrivateSpaFormSenderBackendConfiguration,
} from 'app/utils/PrivateSpaFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  privateSpaFormSenderBackendConfiguration: PrivateSpaFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function PrivateSpaPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      PrivateSpaFormSenderBackend(
        pageContext.privateSpaFormSenderBackendConfiguration,
      ),
    [pageContext.privateSpaFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.spaServicesProps ? (
        <SpaServices {...context.spaServicesProps} />
      ) : null}
      {context.galleryImagesProps ? (
        <GalleryImages variant="compact" {...context.galleryImagesProps} />
      ) : null}
      {context.privateSpaFormProps ? (
        <PrivateSpaForm
          {...(context.privateSpaFormProps
            ? context.privateSpaFormProps
            : null)}
          onPrivateSpaFormSendToBackend={async (data) =>
            await sendFormToBackend(data)
          }
        />
      ) : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
