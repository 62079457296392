import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const SpaServices = memo(function SpaServices({
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>
            <Label>{title}</Label>
          </Title>
        </FadeInUp>
      ) : null}
      <Services row wrap stretch>
        {services.map((item, index) => (
          <Service isOdd={index % 2 === 0} key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  margin: 5.25rem auto 7.5rem;

  @media (max-width: 1023px) {
    margin: 3.75rem auto 5.625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.25rem;
  margin: auto;
  padding: 3.75rem 0;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    border-bottom: 0;
    position: absolute;
    top: calc(50% + 0.3125rem);
    right: 11.667vw;
    left: 11.667vw;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    margin-bottom: 1.875rem;
    padding: 0 1.9375rem;
    &:before {
      display: none;
    }
  }
`

const Label = styled.span`
  max-width: 27.778vw;
  padding: 0 2.8125rem;
  position: relative;
  &:after {
    content: '';
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0.3125rem;
    right: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    max-width: none;
    padding: 0;
    &:after {
      display: none;
    }
  }
`

const Services = styled(FlexBox)``
